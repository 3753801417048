import { ReactComponent as IconLink } from '@assets/icon/open.svg';
import { Badge, Group } from '@mantine/core';
import { InsMemberSearchResult } from '@models/InsMember.type';
import { StatusType } from '@models/InsUserDatabase.type';
import UserAvatar from '@pages/ins/components/UserAvatar';
import { formatLocalDate } from '@utils/date-utils';
import _ from 'lodash';
import React from 'react';
import { TableColumn } from 'react-data-table-component';

export const columns: TableColumn<InsMemberSearchResult>[] = [
  {
    name: 'ลำดับ',
    minWidth: '60px',
    cell: (row) => row?.sequenceOfDonator || '-',
  },
  {
    name: 'status',
    minWidth: '102px',
    cell: (row) => (
      <Badge size="md" className={row.status === StatusType.EXPIRED ? 'bg-disabled text-disabled2' : ''}>
        {row.status}
      </Badge>
    ),
  },
  {
    name: 'User ID',
    minWidth: '120px',
    cell: (row) => <UserAvatar picture={row?.picture} userId={row?.userId} alt={row?.name} />,
  },
  {
    name: 'นามปากกา',
    minWidth: '100px',
    cell: (row) => row?.alias || '-',
  },
  {
    name: 'ชื่อ นามสกุล',
    minWidth: '12%',
    cell: (row) => row?.name || '-',
  },
  {
    name: 'Payment Email',
    minWidth: '12%',
    cell: (row) => row?.paymentEmail || '-',
  },
  {
    name: 'Occupation',
    minWidth: '8%',
    cell: (row) => row?.occupation || '-',
  },
  {
    name: 'แพ็กปัจจุบัน',
    cell: (row) => (
      <Group pr={16} spacing={8} noWrap>
        <Badge variant="filled" size="lg" className="bg-secondary py-4 text-[12px] font-normal text-white">
          {row?.currentPackage || '-'}
        </Badge>
      </Group>
    ),
  },
  {
    name: 'Total Donate',
    minWidth: '110px',
    cell: (row) => row?.totalDonate.toLocaleString() || '-',
  },
  {
    name: '1 เดือน',
    cell: (row) => row.totalOneMonthPackage || '0',
  },
  {
    name: '3 เดือน',
    cell: (row) => row.totalThreeMonthPackage || '0',
  },
  {
    name: '1 ปี',
    cell: (row) => row.totalOneYearPackage || '0',
  },
  {
    name: 'ซื้อครั้งแรก',
    minWidth: '8%',
    cell: (row) => formatLocalDate(row?.firstPaymentDate) || '-',
  },
  {
    name: 'ซื้อครั้งล่าสุด',
    minWidth: '8%',
    cell: (row) => formatLocalDate(row?.lastPaymentDate) || '-',
  },
  {
    name: 'Expired',
    minWidth: '8%',
    cell: (row) => formatLocalDate(row?.expiredAt) || '-',
  },
  {
    name: 'หน้า Profile',
    minWidth: '12%',
    cell: (row) => (
      <a
        href={`${process.env.REACT_APP_NEWSITE_URL}/profile/${row?.userId}`}
        target={row.name}
        rel="noreferrer"
        className="text-secondary-500 underline"
      >
        <Group spacing={6}>
          {_.trim(row.name)}
          <IconLink />
        </Group>
      </a>
    ),
  },
  {
    name: 'เป็นนักเขียน',
    minWidth: '100px',
    cell: (row) => (row?.isWriter ? 'YES' : 'NO'),
  },
];
