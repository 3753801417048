import { IdeaDatabaseUserTagsItems } from '@constants/IdeaDatabase';
import { MultiSelect, TextInput } from '@mantine/core';
import { IdeaDetailRes } from '@models/IdeaDatabase.type';
import React from 'react';

interface IdeaDetailsProps {
  editIdeaData: IdeaDetailRes;
  handleChange: <K extends keyof IdeaDetailRes>(key: K) => (value: IdeaDetailRes[K]) => void;
  setFieldValue: (field: string, value: unknown) => void;
}

const IdeaDetails: React.FC<IdeaDetailsProps> = ({ editIdeaData, handleChange, setFieldValue }) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <TextInput
        size="md"
        radius="md"
        label="ชื่อไอเดีย"
        value={editIdeaData.title}
        onChange={(e) => handleChange('title')(e.currentTarget.value)}
      />
      <MultiSelect
        label="ติดแท็กให้ไอเดีย"
        placeholder="User Tag"
        radius="md"
        size="md"
        clearable
        searchable
        creatable
        getCreateLabel={(query) => <span className="text-primary">{`+ Create "${query}"`}</span>}
        onCreate={(query) => {
          const newTag = { value: query, label: query };
          setFieldValue('tags', [...editIdeaData.tags, query]);
          return newTag;
        }}
        value={editIdeaData.tags}
        onChange={(value) => handleChange('tags')(value)}
        data={IdeaDatabaseUserTagsItems.concat(editIdeaData.tags.map((tag) => ({ value: tag, label: tag })))}
      />
    </div>
  );
};

export default IdeaDetails;
