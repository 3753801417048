import { useIdeaDatabaseDispatch } from '@context/IdeaDatabase.context';
import { DateRangePickerValue } from '@mantine/dates';
import { IdeaDatabaseAction, IdeaDatabaseActionType, IdeaRecommendType, SortType, TypeOfIdea } from '@models/IdeaDatabase.type';

export const useIdeaDatabaseFunc = () => {
  const dispatch = useIdeaDatabaseDispatch();
  const dispatchAndSearch = (actions?: IdeaDatabaseAction[]): void => {
    actions?.forEach((action) => dispatch(action));
    dispatch({ type: IdeaDatabaseActionType.CLICK_SEARCH, payload: true });
    dispatch({ type: IdeaDatabaseActionType.SET_PAGE, payload: 1 });
  };

  const handleChangeSort = (value: SortType): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_SORT, payload: value }]);
  };

  const handleClickSearchIcon = (): void => {
    dispatchAndSearch();
  };

  const handleChangeIdeaTitle = (value: string): void => {
    dispatch({ type: IdeaDatabaseActionType.SET_IDEA_TITLE, payload: value });
  };

  const handleChangeAlias = (value: string): void => {
    dispatch({ type: IdeaDatabaseActionType.SET_ALIAS, payload: value });
  };

  const handleChangeOccupation = (value: string[]): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_OCCUPATION, payload: value }]);
  };

  const handleChangeInskruSelected = (value: string[]): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_INSKRU_SELECTED, payload: value }]);
  };

  const handleChangeTemplate = (value: string[]): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_TEMPLATE_TYPE, payload: value }]);
  };

  const handleChangeIdeaRecommend = (value: IdeaRecommendType): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_IS_RECOMMEND, payload: value }]);
  };

  const handleClickClearSearch = (): void => {
    dispatch({ type: IdeaDatabaseActionType.CLEAR });
  };

  const handleChangeDate = (value: DateRangePickerValue): void => {
    if (value[0] && value[1]) {
      const startDate = new Date(value[0]);
      startDate.setDate(startDate.getDate() + 1);
      const endDate = new Date(value[1]);
      endDate.setDate(endDate.getDate() + 1);
      dispatchAndSearch([
        { type: IdeaDatabaseActionType.DATES, payload: value },
        { type: IdeaDatabaseActionType.SET_PUBLISHED_DATE_START, payload: startDate.toISOString() },
        { type: IdeaDatabaseActionType.SET_PUBLISHED_DATE_END, payload: endDate.toISOString() },
      ]);
    }
  };

  const handleChangeUserTags = (value: string[]): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_USER_TAGS, payload: value }]);
  };

  const handleChangeTypeOfIdea = (value: TypeOfIdea): void => {
    dispatchAndSearch([{ type: IdeaDatabaseActionType.SET_TYPE_OF_IDEA, payload: value }]);
  };

  return {
    handleChangeSort,
    handleChangeAlias,
    handleClickSearchIcon,
    handleChangeIdeaTitle,
    handleChangeOccupation,
    handleChangeTemplate,
    handleChangeIdeaRecommend,
    handleClickClearSearch,
    handleChangeDate,
    handleChangeUserTags,
    handleChangeInskruSelected,
    handleChangeTypeOfIdea,
  };
};
