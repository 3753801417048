import { Avatar, Image, MultiSelect, Select, SelectItem } from '@mantine/core';
import { useFormikContext } from 'formik';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { searchPublishIdea } from '@api/idea/idea.service';
import { searchUser } from '@api/member/member.service';
import { Toast } from '@components/Toast/Toast';
import { CreditIdeaType, IdeaCardModel, IdeaDetailRes, SearchCategory, SearchIdeaReq, UserCardModel } from '@models/IdeaDatabase.type';
import { Pagination } from '@models/Pagination.type';
import { cloneDeep } from 'lodash';

const MAX_ITEMS = 3;
const PAGE_SIZES = 20;
const CREDIT_IDEA_TYPE_OPTIONS = [
  {
    label: 'ไอเดียใน insKru',
    value: 'idea',
  },
  {
    label: 'เพื่อนครูใน insKru',
    value: 'teacher',
  },
];

interface SelectOptionWIthCover extends SelectItem {
  cover: string;
  frame: string;
  description: string;
}

interface FetchOptions {
  keyword: string;
  setOptionsWithCover: (items: SelectOptionWIthCover[]) => void;
  callback?: () => void;
}

const fetchUser = ({ keyword, setOptionsWithCover, callback }: FetchOptions) => {
  searchUser(keyword, 1, PAGE_SIZES).subscribe({
    next: (u: Pagination<UserCardModel>) => {
      const opts = u.data.map((user) => ({
        label: user.name?.trim() || '',
        value: user.id?.toString() || '',
        cover: user.avatar || '',
        frame: user.frame || '',
        description: '',
      }));
      setOptionsWithCover(opts);
      callback?.();
    },
    error: () => {
      setOptionsWithCover([]);
      callback?.();
    },
  });
};

const fetchIdea = ({ keyword, setOptionsWithCover, callback }: FetchOptions) => {
  const newSearch: SearchIdeaReq = {
    text: keyword,
    category: SearchCategory.LATEST,
    pages: 1,
    sizes: PAGE_SIZES,
  };

  searchPublishIdea(newSearch).subscribe({
    next: (u: Pagination<IdeaCardModel>) => {
      const opts = u.data.map((idea) => ({
        label: idea.title?.trim() || '',
        value: idea.id || '',
        cover: idea.cover || '',
        frame: '',
        description: idea.author?.name?.trim() || '',
      }));
      setOptionsWithCover(opts);
      callback?.();
    },
    error: () => {
      setOptionsWithCover([]);
      callback?.();
    },
  });
};

const MultiSelectWithSearch = () => {
  const { values, setFieldValue } = useFormikContext<IdeaDetailRes>();
  const [prevCreditType, setPrevCreditType] = useState<CreditIdeaType | null>(values.creditIdeaType);
  const [options, setOptions] = useState<SelectOptionWIthCover[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectOptionWIthCover[]>([]);
  const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false);
  const prevCreditToValuesRef = React.useRef<string[]>([]);

  const fetchOptions = useCallback(
    (query: string, creditIdeaType: CreditIdeaType | null) => {
      if (!creditIdeaType || !query.trim()) return;

      setIsLoading(true);
      const fetchOpt: FetchOptions = {
        keyword: query,
        setOptionsWithCover: (items) => {
          const newItems = items.filter((item) => item.label?.trim() !== '');

          requestAnimationFrame(() => {
            setOptions((prevOptions) => {
              const selectedOpts = prevOptions.filter((opt) => values.creditTo?.includes(opt.value));
              const uniqueOptions = cloneDeep(selectedOpts);

              newItems.filter((item) => !uniqueOptions.some((opt) => opt.value === item.value)).forEach((item) => uniqueOptions.push(item));

              return uniqueOptions;
            });
            setIsLoading(false);
          });
        },
      };

      if (creditIdeaType === 'teacher') {
        fetchUser(fetchOpt);
      } else {
        fetchIdea(fetchOpt);
      }
    },
    [values.creditIdeaType, values.creditTo]
  );

  const fetchSelectedItems = useCallback(
    (selectedIds: string[], creditIdeaType: CreditIdeaType | null) => {
      if (selectedIds.length === 0 || !creditIdeaType) {
        return;
      }

      setIsLoading(true);
      const fetchOpt: FetchOptions = {
        keyword: selectedIds.join(','),
        setOptionsWithCover: (items) => {
          const currentItemSelected = items.filter((item) => selectedIds.includes(item.value));

          requestAnimationFrame(() => {
            setSelectedOptions((prevSelected) => {
              const newSelected = cloneDeep(prevSelected);

              currentItemSelected
                .filter((item) => !newSelected.some((opt) => opt.value === item.value))
                .forEach((item) => newSelected.push(item));

              return newSelected;
            });

            setOptions((prevOptions) => {
              const combinedOptions = cloneDeep(prevOptions);

              currentItemSelected
                .filter((item) => !combinedOptions.some((opt) => opt.value === item.value))
                .forEach((item) => combinedOptions.push(item));

              return combinedOptions;
            });
            setIsLoading(false);
          });
        },
      };

      if (creditIdeaType === 'teacher') {
        fetchUser(fetchOpt);
      } else {
        fetchIdea(fetchOpt);
      }
    },
    [values.creditIdeaType]
  );

  useEffect(() => {
    if (prevCreditType !== values.creditIdeaType) {
      setPrevCreditType(values.creditIdeaType);

      const resetValues = () => {
        setOptions([]);
        setSelectedOptions([]);
        setFieldValue('creditTo', []);
      };
      resetValues();
    }
  }, [values.creditIdeaType]);

  useEffect(() => {
    if (!values.creditTo?.length) return;

    setOptions((prevOptions) => {
      const combinedOptions = cloneDeep(prevOptions);
      const newItems = selectedOptions.filter(
        (item) => values.creditTo?.includes(item.value) && !combinedOptions.some((opt) => opt.value === item.value)
      );
      combinedOptions.push(...newItems);

      return combinedOptions;
    });
  }, [selectedOptions, values.creditTo]);

  useEffect(() => {
    let isMounted = true;

    if (values.creditTo?.length > 0 && !initialLoadDone && values.creditIdeaType) {
      setTimeout(() => {
        if (isMounted) {
          fetchSelectedItems(values.creditTo, values.creditIdeaType);
          setInitialLoadDone(true);
        }
      }, 50);
    }

    return () => {
      isMounted = false;
    };
  }, [values.creditTo, values.creditIdeaType, initialLoadDone, fetchSelectedItems]);

  useEffect(() => {
    const prevValues = prevCreditToValuesRef.current;
    const currentValues = values.creditTo || [];

    if (!initialLoadDone) {
      prevCreditToValuesRef.current = cloneDeep(currentValues);
      return;
    }

    prevCreditToValuesRef.current = cloneDeep(currentValues);

    const hasChanged = prevValues.length !== currentValues.length || prevValues.some((val, idx) => val !== currentValues[idx]);
    if (hasChanged && currentValues.length > 0) {
      const missingIds = currentValues.filter((id) => !selectedOptions.some((opt) => opt.value === id));
      if (missingIds.length > 0) {
        setTimeout(() => {
          fetchSelectedItems(missingIds, values.creditIdeaType);
        }, 50);
      }
    }
  }, [values.creditTo, values.creditIdeaType, initialLoadDone, selectedOptions, fetchSelectedItems]);

  useEffect(() => {
    if (!searchQuery.trim()) return;

    const handler = setTimeout(() => {
      if (searchQuery.trim() && values.creditIdeaType) {
        fetchOptions(searchQuery, values.creditIdeaType);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, values.creditIdeaType, fetchOptions]);

  const handleChange = (newValues: string[] | null) => {
    if (!newValues) {
      requestAnimationFrame(() => {
        setFieldValue('creditTo', []);
        setSelectedOptions([]);
      });
      return;
    }

    if (newValues.length > MAX_ITEMS) {
      Toast.fire({
        icon: 'error',
        title: 'เพิ่มได้สูงสุด 3 ไอเดีย',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    try {
      const sanitizedValues = newValues.length > MAX_ITEMS ? newValues.slice(0, MAX_ITEMS) : newValues;
      requestAnimationFrame(() => {
        setFieldValue('creditTo', sanitizedValues);
        const newSelectedOptions = options.filter((option) => sanitizedValues.includes(option.value));
        const missingIds = sanitizedValues.filter((id) => !options.some((opt) => opt.value === id));

        setSelectedOptions(newSelectedOptions);

        const hasMissingIds = missingIds.length > 0 && values.creditIdeaType;
        if (hasMissingIds) {
          setTimeout(() => {
            fetchSelectedItems(missingIds, values.creditIdeaType);
          }, 50);
        }
      });
    } catch (error) {
      console.error('Error handling change:', error);
    }
  };

  const SelectItemsWithType = (creditIdeaType: CreditIdeaType | null) => {
    const SelectItems = forwardRef<HTMLDivElement, SelectOptionWIthCover>((props, ref) => {
      if (!creditIdeaType) return <div ref={ref} {...props}></div>;

      if (creditIdeaType === 'teacher') {
        return (
          <div ref={ref} {...props} className={`${props.className} w-full`}>
            <div className="flex gap-2">
              <div className="relative flex items-center justify-center">
                <Avatar src={props.cover} size="sm" radius="xl" className={props.frame ? 'absolute' : ''} />
                {props.frame && <Image src={props.frame} alt="avatar-frame" width={32} height={32} />}
              </div>
              <div className="flex flex-1 gap-1">
                <span className="truncate text-secondary-500">{props.label}</span>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div ref={ref} {...props}>
          <div className="flex gap-3">
            <div className="aspect-[3/2]">
              <Image src={props.cover} alt={props.label} width={65} height={65} fit="contain" />
            </div>
            <div className="flex flex-1 flex-col items-start">
              <span className="w-full truncate">{props.label}</span>
              <span className="w-full truncate text-xs font-light">โดย:&nbsp;{props.description}</span>
            </div>
          </div>
        </div>
      );
    });
    SelectItems.displayName = 'SelectItems';
    return SelectItems;
  };

  return (
    <div className="relative w-full">
      <MultiSelect
        name="creditTo"
        data={options}
        value={values.creditTo || []}
        onChange={handleChange}
        searchable
        clearSearchOnBlur
        onSearchChange={(value) => {
          setSearchQuery(value || '');
        }}
        itemComponent={SelectItemsWithType(values.creditIdeaType)}
        nothingFound={isLoading ? 'กำลังโหลด...' : 'ไม่พบตัวเลือก'}
        clearable
        filter={() => true}
        disabled={!values.creditIdeaType}
        maxDropdownHeight={250}
        dropdownPosition="bottom"
        transition="pop-top-left"
        transitionDuration={80}
        shouldCreate={(value) => !!value && values.creditTo?.includes(value)}
        styles={() => ({
          dropdown: {
            height: 'auto',
            maxHeight: '250px',
            overflow: 'hidden',
            zIndex: 999,
          },
          itemsWrapper: {
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '250px',
            padding: 0,
          },
          item: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexShrink: 0,
            margin: 0,
            padding: '4px 8px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          },
          input: {
            overflow: 'hidden',
          },
          value: {
            overflow: 'hidden',
          },
        })}
      />
    </div>
  );
};

const CreditIdea: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IdeaDetailRes>();

  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      <div className="flex items-center gap-4">
        <Select
          clearable
          name="creditIdeaType"
          value={values.creditIdeaType}
          data={CREDIT_IDEA_TYPE_OPTIONS}
          className="w-48"
          onChange={(value: CreditIdeaType) => setFieldValue('creditIdeaType', value)}
        />
      </div>
      <MultiSelectWithSearch />
    </div>
  );
};

export default CreditIdea;
