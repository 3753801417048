import { Button, Select, SelectItem } from '@mantine/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { editIdea } from '@api/idea/idea.service';
import { IdeaCollection } from '@models/IdeaCollection';
import { IdeaDetailRes } from '@models/IdeaDatabase.type';

import CreditIdea from './CreditIdea';
import FileUpload from './FileUpload';
import IdeaDetails from './IdeaDetails';
import IdeaTemplate from './IdeaTemplate';
import ImageUploader from './ImageUploader';

const MySwal = withReactContent(Swal);

const MESSAGES = {
  DRAFT: 'เปลี่ยนเป็นแบบร่างสำเร็จแล้ว!',
  PUBLISH: 'เปลี่ยนเป็นเผยแพร่สำเร็จแล้ว!',
  ERROR: 'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
};

const EditIdeaForm = () => {
  const { values, setFieldValue } = useFormikContext<IdeaDetailRes>();
  const [preview, setPreview] = useState<string>('');
  const [ideaGroup, setIdeaGroup] = useState<SelectItem[]>([]);

  useEffect(() => {
    ideaCollectionService.getAllIdeaCollection().subscribe((resp: IdeaCollection[]) => {
      const items: SelectItem[] = resp.map((ideaCollection) => ({
        group: `${ideaCollection.year}`,
        value: `${ideaCollection.id}`,
        label: ideaCollection.collectionCategory.name,
      }));
      setIdeaGroup(items);
    });
  }, []);

  useEffect(() => {
    const cover = values.images?.[0]?.image || '';
    setPreview(cover);
  }, [values.images]);

  const handleChange =
    <K extends keyof IdeaDetailRes>(key: K) =>
    (value: IdeaDetailRes[K]) => {
      setFieldValue(key, value);
    };

  const handleEditIdea = (values: IdeaDetailRes, successMessage: string) => {
    const { _id } = values;
    if (_id) {
      editIdea(_id, values).subscribe({
        next: () => {
          MySwal.fire({
            icon: 'success',
            title: successMessage,
            showConfirmButton: false,
            timer: 2000,
          });
        },
        error: () => {
          MySwal.fire({
            icon: 'error',
            title: MESSAGES.ERROR,
            showConfirmButton: false,
            timer: 1500,
          });
        },
      });
    }
  };

  const handleToggleDraft = () => {
    const isDraft = !values.isDraft;
    const updatedValues = { ...values, isDraft };
    handleEditIdea(updatedValues, isDraft ? MESSAGES.DRAFT : MESSAGES.PUBLISH);
  };

  return (
    <>
      <div className="mb-4 flex justify-end gap-4">
        <Button
          type="button"
          className="w-fit rounded-full border border-black bg-transparent px-4 text-xl font-normal text-black no-underline hover:bg-white"
          onClick={handleToggleDraft}
        >
          {values.isDraft ? 'เผยแพร่' : 'เปลี่ยนเป็นแบบร่าง'}
        </Button>
        <Button type="submit" className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
          บันทึก
        </Button>
      </div>

      <div className="flex justify-end">
        <Select
          clearable
          searchable
          label="จัดให้อยู่ใน Collection"
          placeholder="เลือก collection"
          size="md"
          className="w-96"
          data={ideaGroup}
          value={values.ideaCollection ? `${values.ideaCollection}` : null}
          onChange={(value: string) => handleChange('ideaCollection')(parseInt(value))}
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex gap-8">
          <ImageUploader preview={preview} setPreview={setPreview} setFieldValue={setFieldValue} />
          <IdeaDetails editIdeaData={values} handleChange={handleChange} setFieldValue={setFieldValue} />
        </div>

        <div className="grid gap-2">
          <label htmlFor="">ไอเดียนี้ต่อยอดมาจาก ...</label>
          <CreditIdea />
        </div>

        <div className="grid gap-2">
          <label htmlFor="">แบ่งปันไฟล์ที่เกี่ยวข้อง</label>
          <FileUpload />
        </div>

        <IdeaTemplate editIdeaData={values} handleChange={handleChange} />
      </div>
    </>
  );
};

export default EditIdeaForm;
