import axiosGolang from '@api/axiosGolang';
import { UserCardModel } from '@models/IdeaDatabase.type';
import { InsMemberSearchResult } from '@models/InsMember.type';
import InsUserDatabaseFilter from '@models/InsUserDatabase.type';
import { MemberSearchResult } from '@models/Member.type';
import { Pagination } from '@models/Pagination.type';
import UserDatabaseFilter from '@models/UserDatabase.type';
import { Observable, from, map } from 'rxjs';

const url = '/backoffice/users';

export const searchMember = (search: UserDatabaseFilter): Observable<Pagination<MemberSearchResult>> => {
  return from(axiosGolang.post(`${url}/search`, search)).pipe(map((d) => d.data));
};

export const searchInsMember = (search: InsUserDatabaseFilter): Observable<Pagination<InsMemberSearchResult>> => {
  return from(axiosGolang.post(`${url}/ins-members-search`, search)).pipe(map((d) => d.data));
};

export const exportInsMember = (search: InsUserDatabaseFilter) => {
  return from(axiosGolang.post(`${url}/export-ins-member`, search, { responseType: 'blob' })).pipe(map((d) => d.data));
};

export const exportMember = (search: UserDatabaseFilter) => {
  return from(axiosGolang.post(`${url}/export-member`, search, { responseType: 'blob' })).pipe(map((d) => d.data));
};

export const getLevels = (): Observable<string[]> => {
  return from(axiosGolang.get(`${url}/levels`)).pipe(map((d) => d.data));
};

export const getSubjects = (): Observable<string[]> => {
  return from(axiosGolang.get(`${url}/subjects`)).pipe(map((d) => d.data));
};

export const searchUser = (text: string = '', pages: number = 1, sizes: number = 6): Observable<Pagination<UserCardModel>> => {
  const params = { text, pages, sizes };
  return from(axiosGolang.get(`/client/users/search`, { params })).pipe(map((d) => d.data));
};
