import {
  IdeaDatabaseInskruSelectedType,
  IdeaDatabaseTemplateType,
  IdeaInskruSelectedType,
  IdeaRecommendType,
  SortType,
  TypeOfIdea,
} from '@models/IdeaDatabase.type';

export interface SelectType<T> {
  value: T;
  label: string;
}

export const TypeOfIdeaItems: SelectType<TypeOfIdea>[] = [
  { label: 'Published', value: TypeOfIdea.PUBLISHED },
  { label: 'ไอเดียที่ถูกลบ', value: TypeOfIdea.DELETED },
];

export const IdeaDatabaseSortItems: SelectType<SortType>[] = [
  { label: 'Published ล่าสุด', value: SortType.LATEST_PUBLISHED },
  { label: 'ยอดอ่าน', value: SortType.TOP_VIEW },
  { label: 'ยอด Comment', value: SortType.TOP_COMMENT },
  { label: 'ยอดดาวน์โหลด', value: SortType.TOP_DOWNLOAD },
  { label: 'ยอดเก็บไว้อ่าน', value: SortType.TOP_SAVED },
  { label: 'inspire Reaction', value: SortType.TOP_INSPIRE_REACTION },
  { label: 'flower Reaction', value: SortType.TOP_FLOWER_REACTION },
];

export const IdeaRecommendItems: SelectType<IdeaRecommendType>[] = [
  { label: 'ทั้งหมด', value: IdeaRecommendType.ALL },
  { label: 'ไอเดียแนะนำ', value: IdeaRecommendType.YES },
  { label: 'ไอเดียทั่วไป', value: IdeaRecommendType.NO },
];

export const IdeaDatabaseInskruSelectItems: SelectType<IdeaDatabaseInskruSelectedType>[] = [
  { label: 'Awareness', value: IdeaDatabaseInskruSelectedType.AWARENESS },
  { label: 'Creative Thinking', value: IdeaDatabaseInskruSelectedType.CREATIVE_THINKING },
  { label: 'Collaboration', value: IdeaDatabaseInskruSelectedType.COLLABORATION },
  { label: 'Critical Thinking', value: IdeaDatabaseInskruSelectedType.CRITICAL_THINKING },
  { label: 'Grit', value: IdeaDatabaseInskruSelectedType.GRIT },
  { label: 'Growth Mindset', value: IdeaDatabaseInskruSelectedType.GROWTH_MINDSET },
  { label: 'Participation', value: IdeaDatabaseInskruSelectedType.PARTICIPATION },
  { label: 'Learning Environment', value: IdeaDatabaseInskruSelectedType.LEARNING_ENVIRONMENT },
  { label: 'Relationship', value: IdeaDatabaseInskruSelectedType.RELATIONSHIP },
  { label: 'Supportive Relationship', value: IdeaDatabaseInskruSelectedType.SUPPORTIVE_RELATIONSHIP },
];

export const IdeaInskruSelectedItems: SelectType<IdeaInskruSelectedType>[] = [
  { label: 'Participation', value: IdeaInskruSelectedType.PARTICIPATION },
  { label: 'Learning Environment', value: IdeaInskruSelectedType.LEARNING_ENVIRONMENT },
  { label: 'Relationship', value: IdeaInskruSelectedType.RELATIONSHIP },
  { label: 'Supportive Relationship', value: IdeaInskruSelectedType.SUPPORTIVE_RELATIONSHIP },
  { label: 'Growth Mindset', value: IdeaInskruSelectedType.GROWTH_MINDSET },
  { label: 'Awareness', value: IdeaInskruSelectedType.AWARENESS },
  { label: 'Grit', value: IdeaInskruSelectedType.GRIT },
  { label: 'Collaboration', value: IdeaInskruSelectedType.COLLABORATION },
  { label: 'Critical Thinking', value: IdeaInskruSelectedType.CRITICAL_THINKING },
  { label: 'Creative Thinking', value: IdeaInskruSelectedType.CREATIVE_THINKING },
  { label: 'Deleted Selected', value: IdeaInskruSelectedType.DELETED_SELECTED },
];

export const IdeaDatabaseTemplateItems: SelectType<IdeaDatabaseTemplateType>[] = [
  { label: 'คลิป/ไฟล์ อธิบายนิดเดียว', value: IdeaDatabaseTemplateType.CLIP_FILE },
  { label: 'ให้อินสครูช่วย', value: IdeaDatabaseTemplateType.INSKRU },
  { label: 'ขอเล่าเอง', value: IdeaDatabaseTemplateType.TELL },
];

export const IdeaDatabaseUserTagsItems: SelectType<string>[] = [
  { label: 'ภาษาไทย', value: 'ภาษาไทย' },
  { label: 'ภาษาอังกฤษ', value: 'ภาษาอังกฤษ' },
  { label: 'คณิตศาสตร์', value: 'คณิตศาสตร์' },
  { label: 'วิทยาศาสตร์', value: 'วิทยาศาสตร์' },
  { label: 'ฟิสิกส์', value: 'ฟิสิกส์' },
  { label: 'เคมี', value: 'เคมี' },
  { label: 'ชีววิทยา', value: 'ชีววิทยา' },
  { label: 'วิทยาการคำนวณ', value: 'วิทยาการคำนวณ' },
  { label: 'สังคมศึกษา', value: 'สังคมศึกษา' },
  { label: 'แนะแนว', value: 'แนะแนว' },
  { label: 'ดนตรี', value: 'ดนตรี' },
  { label: 'นาฏศิลป์', value: 'นาฏศิลป์' },
  { label: 'ศิลปะ', value: 'ศิลปะ' },
  { label: 'สุขศึกษาและพลศึกษา', value: 'สุขศึกษาและพลศึกษา' },
  { label: 'คอมพิวเตอร์', value: 'คอมพิวเตอร์' },
  { label: 'การงานอาชีพ', value: 'การงานอาชีพ' },
  { label: 'ภาษาต่างประเทศ', value: 'ภาษาต่างประเทศ' },
  { label: 'กิจกรรมเสริม', value: 'กิจกรรมเสริม' },
  { label: 'เกมและกิจกรรม', value: 'เกมและกิจกรรม' },
  { label: 'กิจกรรมในโรงเรียน', value: 'กิจกรรมในโรงเรียน' },
  { label: 'ทบทวนบทเรียน', value: 'ทบทวนบทเรียน' },
  { label: 'วิจัยในชั้นเรียน', value: 'วิจัยในชั้นเรียน' },
  { label: 'การจัดการชั้นเรียน', value: 'การจัดการชั้นเรียน' },
  { label: 'เทคนิคการสอน', value: 'เทคนิคการสอน' },
  { label: 'เติมความรู้', value: 'เติมความรู้' },
  { label: 'เนื้อหาสาระรายวิชา', value: 'เนื้อหาสาระรายวิชา' },
  { label: 'การวัดและการประเมินผล', value: 'การวัดและการประเมินผล' },
  { label: 'ตัวช่วยครู', value: 'ตัวช่วยครู' },
  { label: 'แผนการสอน', value: 'แผนการสอน' },
  { label: 'โฮมรูม', value: 'โฮมรูม' },
  { label: 'ใบงาน', value: 'ใบงาน' },
  { label: 'วิดีโอ', value: 'วิดีโอ' },
  { label: 'Powerpoint', value: 'Powerpoint' },
  { label: 'Boardgame', value: 'Boardgame' },
  { label: 'สื่อทำมือและอุปกรณ์', value: 'สื่อทำมือและอุปกรณ์' },
  { label: 'เทคโนโลยีการสอน', value: 'เทคโนโลยีการสอน' },
  { label: 'STEM', value: 'STEM' },
  { label: 'STEAM', value: 'STEAM' },
  { label: 'ProjectBasedLearning', value: 'ProjectBasedLearning' },
  { label: 'CommunityBasedLearning', value: 'CommunityBasedLearning' },
  { label: 'CompetencyBasedLearning', value: 'CompetencyBasedLearning' },
  { label: 'ProblemBasedLearning', value: 'ProblemBasedLearning' },
  { label: 'จิตศึกษา', value: 'จิตศึกษา' },
  { label: 'ทักษะการสื่อสาร', value: 'ทักษะการสื่อสาร' },
  { label: 'ทักษะการคิดวิเคราะห์', value: 'ทักษะการคิดวิเคราะห์' },
  { label: 'ทักษะความคิดสร้างสรรค์', value: 'ทักษะความคิดสร้างสรรค์' },
  { label: 'ทักษะการร่วมมือ', value: 'ทักษะการร่วมมือ' },
  { label: 'ทักษะการตระหนักรู้ในตนเอง', value: 'ทักษะการตระหนักรู้ในตนเอง' },
  { label: 'ผู้เรียนรู้', value: 'ผู้เรียนรู้' },
  { label: 'ผู้ร่วมสร้างสรรค์นวัตกรรม', value: 'ผู้ร่วมสร้างสรรค์นวัตกรรม' },
  { label: 'พลเมืองที่เข้มแข็ง', value: 'พลเมืองที่เข้มแข็ง' },
  { label: 'Grit', value: 'Grit' },
  { label: 'GrowthMindset', value: 'GrowthMindset' },
  { label: 'การฟัง', value: 'การฟัง' },
  { label: 'การพูด', value: 'การพูด' },
  { label: 'การอ่าน', value: 'การอ่าน' },
  { label: 'การเขียน', value: 'การเขียน' },
  { label: 'หลักการใช้ภาษาไทย', value: 'หลักการใช้ภาษาไทย' },
  { label: 'วรรณคดีและวรรณกรรม', value: 'วรรณคดีและวรรณกรรม' },
  { label: 'ไวยกรณ์', value: 'ไวยกรณ์' },
  { label: 'การสื่อสาร', value: 'การสื่อสาร' },
  { label: 'จำนวนและพีชคณิต', value: 'จำนวนและพีชคณิต' },
  { label: 'การวัดและเรขาคณิต', value: 'การวัดและเรขาคณิต' },
  { label: 'สถิติและความน่าจะเป็น', value: 'สถิติและความน่าจะเป็น' },
  { label: 'แคลคูลัส', value: 'แคลคูลัส' },
  { label: 'ศาสนา ศีลธรรม จริยธรรม', value: 'ศาสนา ศีลธรรม จริยธรรม' },
  { label: 'ภูมิศาสตร์', value: 'ภูมิศาสตร์' },
  { label: 'ประวัติศาสตร์', value: 'ประวัติศาสตร์' },
  { label: 'พลเมือง', value: 'พลเมือง' },
  { label: 'วัฒนธรรม', value: 'วัฒนธรรม' },
  { label: 'กฎหมาย', value: 'กฎหมาย' },
  { label: 'ปรัชญา', value: 'ปรัชญา' },
  { label: 'การเมือง', value: 'การเมือง' },
  { label: 'ภูมิปัญญา', value: 'ภูมิปัญญา' },
  { label: 'สารบริสุทธิ์', value: 'สารบริสุทธิ์' },
  { label: 'อะตอม', value: 'อะตอม' },
  { label: 'เซลล์', value: 'เซลล์' },
  { label: 'พืช', value: 'พืช' },
  { label: 'พลังงานความร้อน', value: 'พลังงานความร้อน' },
  { label: 'ลมฟ้าอากาศ', value: 'ลมฟ้าอากาศ' },
  { label: 'สารละลาย', value: 'สารละลาย' },
  { label: 'ระบบต่างๆ ในร่างกาย', value: 'ระบบต่างๆ ในร่างกาย' },
  { label: 'แรงและการเคลื่อนที่', value: 'แรงและการเคลื่อนที่' },
  { label: 'งานและพลังงาน', value: 'งานและพลังงาน' },
  { label: 'การแยกสาร', value: 'การแยกสาร' },
  { label: 'โลก ดาราศาสตร์และอวกาศ', value: 'โลก ดาราศาสตร์และอวกาศ' },
  { label: 'ภัยพิบัติทางธรรมชาติ', value: 'ภัยพิบัติทางธรรมชาติ' },
  { label: 'พันธุศาสตร์', value: 'พันธุศาสตร์' },
  { label: 'คลื่น', value: 'คลื่น' },
  { label: 'แสง', value: 'แสง' },
  { label: 'ปฏิกิริยาเคมี', value: 'ปฏิกิริยาเคมี' },
  { label: 'วัสดุ', value: 'วัสดุ' },
  { label: 'ไฟฟ้า', value: 'ไฟฟ้า' },
  { label: 'ระบบนิเวศ', value: 'ระบบนิเวศ' },
  { label: 'โมเมนตัม', value: 'โมเมนตัม' },
  { label: 'ของไหล', value: 'ของไหล' },
  { label: 'ความร้อนและแก๊ส', value: 'ความร้อนและแก๊ส' },
  { label: 'เสียง', value: 'เสียง' },
  { label: 'ฟิสิกส์อะตอม', value: 'ฟิสิกส์อะตอม' },
  { label: 'นิวเคลียร์และอนุภาค', value: 'นิวเคลียร์และอนุภาค' },
  { label: 'ทฤษฎีสัมพัทธภาพ', value: 'ทฤษฎีสัมพัทธภาพ' },
  { label: 'กลศาสตร์', value: 'กลศาสตร์' },
  { label: 'แม่เหล็กไฟฟ้า', value: 'แม่เหล็กไฟฟ้า' },
  { label: 'ธาตุและสารประกอบ', value: 'ธาตุและสารประกอบ' },
  { label: 'กรดเบส', value: 'กรดเบส' },
  { label: 'พันธะเคมี', value: 'พันธะเคมี' },
  { label: 'ปริมาณสารสัมพันธ์', value: 'ปริมาณสารสัมพันธ์' },
  { label: 'อัตราการเกิดปฏิกิริยาเคมี', value: 'อัตราการเกิดปฏิกิริยาเคมี' },
  { label: 'สมดุลเคมี', value: 'สมดุลเคมี' },
  { label: 'ไฟฟ้าเคมี', value: 'ไฟฟ้าเคมี' },
  { label: 'เคมีอินทรีย์', value: 'เคมีอินทรีย์' },
  { label: 'โพลิเมอร์', value: 'โพลิเมอร์' },
  { label: 'ชีวเคมี', value: 'ชีวเคมี' },
  { label: 'ชีววิทยาของสัตว์', value: 'ชีววิทยาของสัตว์' },
  { label: 'วิวัฒนาการ', value: 'วิวัฒนาการ' },
  { label: 'taxonomy', value: 'taxonomy' },
  { label: 'ดนตรีไทย', value: 'ดนตรีไทย' },
  { label: 'ดนตรีสากล', value: 'ดนตรีสากล' },
  { label: 'ประวัติดนตรี', value: 'ประวัติดนตรี' },
  { label: 'ประเภทเครื่องดนตรี', value: 'ประเภทเครื่องดนตรี' },
  { label: 'นาฏศัพท์', value: 'นาฏศัพท์' },
  { label: 'บทละครและการแสดง', value: 'บทละครและการแสดง' },
  { label: 'ทัศนศิลป์', value: 'ทัศนศิลป์' },
  { label: 'ทัศนธาตุ', value: 'ทัศนธาตุ' },
  { label: 'หลักการออกแบบ', value: 'หลักการออกแบบ' },
  { label: 'การจัดองค์ประกอบศิลป์', value: 'การจัดองค์ประกอบศิลป์' },
  { label: 'ประวัติศาสตร์ศิลป์', value: 'ประวัติศาสตร์ศิลป์' },
  { label: 'วิเคราะห์ วิพากษ์ วิจารณ์ศิลปะ', value: 'วิเคราะห์ วิพากษ์ วิจารณ์ศิลปะ' },
  { label: 'สุนทรียศาสตร์', value: 'สุนทรียศาสตร์' },
  { label: 'ศิลปิน', value: 'ศิลปิน' },
  { label: 'ศิลปะบำบัด', value: 'ศิลปะบำบัด' },
  { label: 'ประเภทของงานศิลปะ', value: 'ประเภทของงานศิลปะ' },
  { label: 'การเจริญเติบโตและพัฒนาการ', value: 'การเจริญเติบโตและพัฒนาการ' },
  { label: 'เพศศึกษา', value: 'เพศศึกษา' },
  { label: 'เพศวิถี', value: 'เพศวิถี' },
  { label: 'สุขภาพ', value: 'สุขภาพ' },
  { label: 'โรคติดต่อ', value: 'โรคติดต่อ' },
  { label: 'การเคลื่อนไหว ออกกำลังกาย', value: 'การเคลื่อนไหว ออกกำลังกาย' },
  { label: 'กีฬาสากล', value: 'กีฬาสากล' },
  { label: 'กีฬาไทย', value: 'กีฬาไทย' },
  { label: 'การคิดเชิงคำนวณ', value: 'การคิดเชิงคำนวณ' },
  { label: 'เท่าทันสื่อและสารสนเทศ', value: 'เท่าทันสื่อและสารสนเทศ' },
  { label: 'เทคโนโลยีดิจิทัล', value: 'เทคโนโลยีดิจิทัล' },
  { label: 'Empowerment', value: 'Empowerment' },
  { label: 'การจัดการและวางแผนชีวิต', value: 'การจัดการและวางแผนชีวิต' },
  { label: 'การแก้ปัญหา', value: 'การแก้ปัญหา' },
  { label: 'การคิดและตัดสินใจ', value: 'การคิดและตัดสินใจ' },
  { label: 'การปรับตัว', value: 'การปรับตัว' },
  { label: 'อาชีพ', value: 'อาชีพ' },
  { label: 'การพัฒนาตนเอง', value: 'การพัฒนาตนเอง' },
  { label: 'การจัดการอารมณ์', value: 'การจัดการอารมณ์' },
  { label: 'การให้คำปรึกษา', value: 'การให้คำปรึกษา' },
];

export const IdeaTemplateType: SelectType<string>[] = [
  { label: 'คลิป/ไฟล์ อธิบายนิดเดียว', value: 'CLIP_FILE' },
  { label: 'ให้อินสครูช่วย', value: 'INSKRU' },
  { label: 'ขอเล่าเอง', value: 'TELL' },
];
